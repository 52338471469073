body {
  font-family: Arial, sans-serif;
  background-color: eff2f5;

  
  
  margin: 100;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;
}

.register-selection-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between buttons */
}

/* Force full screen width and height for the container */
.custom-fullscreen-container {
  

  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #021230; 
  background-size: cover;/* Background color */
  background-position-x: center;
  
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  
}

/* Header styling */
.custom-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  gap: 10px;
}

/* Logo styling */
.custom-logo {
  height: 80px;
  color: #ff6d00;
}

/* Title text styling */
.custom-title-text {
  font-family:"Encode Sans Semi Expanded", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

/* Main content styling */
.custom-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 0 80px;
}

/* Login container styling */
.custom-login-container {
  background-color: #0d1b2a;
  padding: 30px;
  padding-top: 40px;
  border-radius: 8px;
  margin-bottom: 50px;
  color: white;
  width: 35%;
  z-index: 2;
}

/* Login form styling */
.custom-login-form {
  width: 100%;
}

/* Title inside login form */
.custom-title {
  font-size: 32px;
  margin-bottom: 30px;
}

/* Input container */
.custom-input-container {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

/* Input icon */
.custom-icon {
  margin-right: 10px;
}

/* Input field */
.custom-input-container input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

/* Login button */
.custom-button {
  background-color: #ff6d00;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Login button hover effect */
.custom-button:hover {
  background-color: #5a6bdb;
}

/* Link below the login button */
.custom-form-link {
  margin-top: 20px;
  text-align: center;
}

/* Link styling */
.custom-form-link a {
  color: #ff6d00;
  text-decoration: none;
}

/* Link hover effect */
.custom-form-link a:hover {
  text-decoration: underline;
}

/* Globe container positioning */
.custom-globe-container {
  position: absolute;
  bottom: -85%;
  right: -15%;
  width: 75%;
  height: auto;
  z-index: 1;
}

/* Globe video styling */
.custom-revolving-globe {
  width: 100%;
  height: 100%;
}

/* Navbar button styling */
.custom-navbar-buttons {
  display: flex;
  gap: 15px;
  position: absolute;
  top: 20px;
  right: 50px;
}

/* Navbar button styling */
.custom-navbar-login-button,
.custom-navbar-register-button {
  padding: 10px 20px;
  border: 2px solid white;
  background: transparent;
  color: white;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px;
}

/* Register button styling */
.custom-navbar-register-button {
  background: #ff6d00;
}

/* VideoDetails.css */
.video-details-container {
  display: flex;
  flex-direction: row;
}

.video-details {
  flex: 1;
  margin-right: 20px;
}

.video-graphs {
  flex: 2;
  display: flex;
  flex-direction:column;
  gap: 20px;
  
}

.chart-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
}

:root {
  --background-color: #fefefe;
  --text-color: #333;
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --card-background-color: #ffffff;
  --card-text-color: #333;
  --accent-color: #FF4081;
  --dark-background-color: #1e1e2d;
  --dark-card-background-color: #27293d;
  --dark-text-color: #ffffff;
  --dark-accent-color: #6c63ff;
}


.fullscreen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffe0b2;
}

.login-box {
  width: 400px;
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.login-logo {
  width: 60px;
  /* Adjusted for more accurate spacing */
}

.login-title {
  font-size: 24px;
  margin-bottom: 25px; /* Adjusted for more accurate spacing */
  color: #333;
}

.toggle-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 14px; /* Adjusted to reduce the font size slightly */
  font-weight: 400; /* Lightened the font weight */
  color: grey;
  cursor: pointer;
  padding-bottom: 5px;
  transition: color 0.3s;
}

.toggle-button.active {
  color: #ff6d00; /* Purple color when active */
  border-bottom: 2px solid #ff6d00;
}

/* Removed hover effect */
.toggle-button:hover {
  color: grey; /* Keep the color grey even on hover */
}

.input-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
}

.input-container input {
  border: none;
  background: transparent;
  outline: none;
  padding-left: 10px;
  width: 100%;
}

.input-icon {
  color: #bbb;
}

.submit-button {
  width: 100%;
  background-color: #ff6d00;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 50px;
}

.submit-button:hover {
  background-color: #3e7edb;
}

.forgot-password-link {
  margin-top: 15px;
  color: #ff6d00;
}

.forgot-password-link a {
  text-decoration: none;
  color: inherit;
}











.main-content {
  flex: 1;
  padding: 10px;
 
  
}

.filters-container {
  background: var(--card-background-color);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  position: fixed;
  top: 120px;
  left: 260px;
  z-index: 10;
  height: calc(100vh - 110px); /* Adjust this value if needed */
  overflow-y: auto;
}
.filters-container h2 {
  margin-top: 0;
  color: var(--accent-color);
}

.filters-container label {
  display: block;
  margin-bottom: 10px;
  color: var(--text-color);
}

.filters-container input[type="text"],
.filters-container input[type="number"],
.filters-container input[type="date"],
.filters-container select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  color: var(--text-color);
  background: var(--card-background-color);
  transition: all 0.3s;
}

.filters-container input[type="checkbox"] {
  margin-right: 5px;
}

.table-container {

  background: var(--card-background-color);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
   /* Adjusted for sidebar width */
  margin-top: 10px; /* Adjusted for filter section */
  width:60px;   
}



.data-table th {
  background-color: #555; /* Slightly lighter background for header */
 
}

.dark-mode .filters-container,
.dark-mode .data-table th,
.dark-mode .data-table td {
  background-color: #333; /* Dark background for table rows in dark mode */
  color: white; /* White text color in dark mode */
}

.dark-mode .data-table th {
  background-color: #555; /* Slightly lighter background for header in dark mode */
}

.export-button {
  margin-top: 10px;
  display: block;
}


.details-button,
.view-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}


.details-button {
  background: var(--primary-color);
  color: white;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.card-text {
  font-size: 0.9rem;
  color: #6c757d;
}

.video-stats {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.video-stats i {
  margin-right: 5px;
}

.button {
  width: 100%;
  margin-top: 5px;
}


.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.data-table {
  width: 60%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.data-table th {
  background-color: #f8f9fa;
}

.data-table tbody tr:hover {
  background-color: #f1f1f1;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination-container .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #ffffff;
}

.pagination-container .page-link {
  color: #007bff;
}

.pagination-container .page-link:hover {
  background-color: #e9ecef;
}




.chart-container {
  height: 300px; /* Ensure a fixed height for the charts */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.video-details-container {
  display: flex;
  align-items: right;
}

.video-details {
  flex: 1 1 300px;
  margin-right: 20px;
}

.video-graphs {
  flex: 2 1 200px;
}

.video-graphs .chart-container {
  height: 200px; /* Ensure a fixed height for the charts */
  margin-bottom: 20px;
}



/* Form Container Styles */
.form-container {
  padding: 40px; /* Reduced padding for a sleeker look */
  background-color: #ffffff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-top: 40px; /* Space at the top */
  margin-right: auto; /* Center align horizontally */
  margin-bottom: 40px; /* Space at the bottom */
  max-width: 700px; /* Limit the width for better readability */
  font-weight:bold; /* Slightly bolder text */
  color: #333; /* Dark text for readability */
  font-family: 'Roboto', sans-serif; /* Modern font */
}

.button1 {
  padding: 10px 20px; /* Comfortable button padding */
  margin-top: 30px; /* Space above the button */
  background-color: #1976d2; /* Primary blue color */
  color: #ffffff; /* White text */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.button1:hover {
  background-color: #1565c0; /* Darker blue on hover */
}

/* Additional styles for form elements */
.form-group {
  margin-bottom: 20px; /* Space between form elements */
}

.form-label {
  font-size: 16px; /* Larger font size for labels */
  margin-bottom: 8px; /* Space below labels */
}

.form-control {
  padding: 10px; /* Padding inside inputs */
  border-radius: 4px; /* Rounded corners for inputs */
  border: 1px solid #ddd; /* Light border color */
  font-size: 14px; /* Consistent font size */
}



/* Filters Styles */
.filters {
  margin-top: 20px;
  margin-left: 30px;
}

.custom-date-filter {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

/* Card Styles */
.card {
  border-radius: 50px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
 
  font-size: 15px;
  height:10px;
  background-color: #bfbeb9;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 4px 4px 20px rgba(76, 29, 186, 0.2);
}

.card-footer {

  align-items: center;
  justify-content: center;
}

.sticky-note {
  border-radius: 50px;
  box-shadow: 2px 2px 10px #6e6b6b1a;
  background-color: #4b4942;
  color: black;
  font-size: 15px;
}

.cards-container {
  margin-top: 20px;
  margin-right: 30px;
 
  padding-left: 30px;
  

  
}

.main-content {
  margin-top: 10px;
  margin-left: 70px;
  padding-top: 20px;
 
}

.dashboard {
  display: block;
  position: relative;
}

.welcome-note {

  font-size: 15px;
  margin-top:20px;

  

}

.left-container{
  padding-left:100px;
}



.button2{
  padding-top: 10px;
  margin-top: 30px;
  
}



/* Form Container Styles */


.video-entry-page{
  margin-top:30px;  /* Ensure it is below the top bar */
  padding-bottom: 50px;
  margin-left: 450px;
  
  background: #d7dbfb;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  height: 600px;
  align-items: center;
  width: 250px;
  display: block; 
   
  align-items: flex-end;
  
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: left;
}

.video-entry-container h2 {
  margin-bottom: 20px;
}

.dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
}



.dropdown-container .add-button {
  margin-left: 10px;
  cursor: pointer;
  color: #1877f2;
}

.error {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.success {
  color: green;
  margin-top: 10px;
  text-align: center;
}

/*approve*/
.pending-writers-container {
  
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0  rgba(0, 0, 0, 0.1);
  
  
}

.pending-writers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pending-writers-table th, .pending-writers-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
}

.pending-writers-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.pending-writers-table td {
  text-align: left;
}



.filters select, .filters input {
  margin-right: 10px;
}

button {
  margin-right: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}




.writers-management-container {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.settings-container {
  display: flex;
  justify-content: space-between;
}


.api-settings, .workspace-settings {
  width: 45%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #ddd; /* Add border around the sections */
}

.api-key-form label,
.api-key-form input,
.api-key-form button {
  display: block;
  margin: 10px 0;
}

.api-key-form p {
  word-wrap: break-word; /* Ensures long text wraps properly */
  white-space: normal; /* Allows breaking text to new lines */
  border: 1px solid #ddd; /* Add border around the text */
  padding: 5px; /* Add padding inside the border */
  border-radius: 4px; /* Slightly round the corners */
  background-color: #f9f9f9; /* Light background for contrast */
}

.api-key-form input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd; /* Border around input fields */
}

.api-key-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.workspace-list {
  margin-top: 20px;
}

.workspace-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: 1px solid #ddd; /* Add border around workspace cards */
}

.board-list {
  list-style-type: none;
  padding: 0;
}

.board-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.set-target-button,
.fetch-workspaces-button,
.update-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.update-button {
  margin-top: 10px;
}

.list-id-input {
  margin-top: 20px;
}

.error {
  color: red;
}

.grid-item {
  width: 100%;
  max-width: 100%;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-bottom: 20px;
}

.grid-item:hover {
  transform: translateY(-5px);
}

.grid-item .video-preview {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #ddd;
}

.grid-item .video-stats {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}

.grid-item .video-stats i {
  margin-right: 5px;
}

.grid-item .video-info {
  padding: 15px;
  font-size: 14px;
  color: gray;
  text-align: center;
}



.grid-item .btn {
  width: 100%;

}

/* Ensure the table container doesn't overflow */
.table-responsive {
  overflow-x: auto;
  margin-bottom: 1.5rem;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

/* Style the table to have a consistent width */
.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th, .data-table td {
  padding: 10px;
  text-align: left;
  vertical-align: middle;
  border: 1px solid #ddd;
  white-space: nowrap; /* Prevent text wrapping */
}

.data-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

/* Checkbox alignment */
.data-table th:first-child, .data-table td:first-child {
  text-align: center;
  width: 40px;
}

/* Button links */
.data-table a {
  color: #007bff;
  text-decoration: none;
}

.data-table a:hover {
  text-decoration: underline;
}

/* Adjust the cell widths */
.data-table th, .data-table td {
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}


/* Align buttons and pagination centrally */
.button-container, .pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 900px) {
  .data-table th, .data-table td {
    padding: 5px;
    font-size: 8px;
  }

  .button-container, .pagination-container {
    flex-direction: column;
    align-items: center;
  }

  .pagination-container .pagination {
    margin-top: 10px;
  }
}

/* styles.css */
.repost-entries-page {
  padding-top: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.repost-table {
  margin-top: 30px;
}

.repost-container{
  width:1200px ;

}

select {
  -webkit-appearance: none; /* Removes default dropdown styles */
  -moz-appearance: none;
  appearance: none;
  background-color: #f5f5f5; /* Clean background */
  
  background-position: right 10px center; /* Adjust position for arrow */
  background-repeat: no-repeat;
  padding-left: 10px;/* Space for the arrow */
  border: none;
  width: 100%;

}

select:focus {
  border-color: #ff6d00; /* Highlight when focused */
  outline: none;
}