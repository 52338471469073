/* Dashboard.css */

  
  
  
  
  
  .chart-container {
    height: 300px; /* Increased height for bigger charts */
    margin-bottom: 20px;
    overflow: auto;
  }

  
  
  
  .chart {
    margin-bottom: 20px;
    
  }

  
  .data-table th,
  .data-table td {
    text-align: center;
  }
  
  .chart-container {
    position:relative;
  }
 