/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 7000px;
  width:63px;
  background-color:rgb(2,19,48);
  color: white;
  transition: 30px;
  z-index: 1000;
  margin-top: 30px;
  padding-top: 15px;
  
  

}

.sidebar:hover {
  width: 240px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  display: flex;
  align-items: center;
}

.sidebar ul li a {
  color: #fff;
  padding: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar ul li a span {
  margin-left: 10px;
  display: none;
}

.sidebar:hover ul li a span {
  display: flex;
  color: white;
}

.sidebar ul li a i {
  font-size: 24px;
}

.nav-item {
  width: 100%;
  padding: 10px;
}

.nav-link {
  color: white;
  display: flex;
  align-items: center;
}

.nav-link .fa {
  margin-right: 5px;
}

.nav-link:hover .fa,
.nav-link.active .fa {
  background-color: rgb(112,129,255);
  color: white;
  border-radius: 80% ;
  margin-right: 30px;
  padding: 11px 10px 9px 11px;
  
  
}

.nav-link .link-text {
  display: none;
}

.sidebar:hover .nav-link .link-text {
  display: inline;
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  padding: 0 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  z-index: 1000;
}

.left-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 30px;
  width: 80px;
  background-image: url('../logo5.png');
  background-size: cover;
  background-position:left;
  background-repeat: no-repeat;
}

.divider {
  height: 30px;
  width: 1px;
  background-color: #dee2e6;
  margin: 0 10px;
}

.section-title-container {
  display: flex;
  align-items: center;
}

.section-title {
  font-size: 18px;
  margin: 0 10px 0 0; /* Space between text and icon */
}

.section-icon {
  height: 16px;
  width: 16px;
}

.top-bar-controls {
  display: flex;
  align-items: center;
}

.time-info {
  margin-right: 20px;
  font-size: 12px;
  color: #7081ff;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
  color: #7081ff;

}
.menu-icon:hover {
  font-size: 24px;
  cursor: pointer;
  color: #394de8;

}
.symbol{
  color: #7081ff;
  gap:5px;

}



.dropdown-menu p {
  margin: 0;
  cursor: pointer;
  padding: 15px 20px;
}

.dropdown-menu p:hover {
  color: #7081ff;
}

